<template>
	<v-container>
		<v-row>
			<v-col cols="6">
				<v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="sale"
                  label="Название"
                  placeholder="Название"
                  outlined
                  :items="searchItems"
                  :search-input.sync="searchString"
                  :item-text="'title'"
                  :item-value="'title'"
                  :loading="searchIsLoading"
                  :return-object="false"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content @click="selectSale(data.item)">
                        <v-list-item-title v-html="data.item.title"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-combobox>
              </v-col>
              <v-col>
                <v-img
                  v-if="imageUrl"
                  :key="imageKey"
                  :src="'https://backend.telestatic.ru/upload/sale_images/' + imageUrl"
                  max-height="400"
                  border="1"
                ></v-img>
                <vue-dropzone
                  v-else
                  id="dropzone"
                  ref="dropzone"
                  class="mb-3"
                  :options="dropzoneOptions"
                  @vdropzone-success="uploadSuccess"
                  @vdropzone-file-added="updateDZHeaders"
                ></vue-dropzone>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-row>
                  <v-col cols="3">
                    <v-btn-toggle v-model="hours" color="deep-purple accent-3" borderless>
                      <v-btn v-for="h in ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']"
                            :key="h"
                            :value="h"
                            text
                            :disabled="hourDisabled(h)"
                      >
                        {{h}}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="3">
                    <v-btn-toggle v-model="minutes" color="deep-purple accent-3" borderless>
                      <v-btn
                          v-for="m in ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                          :key="m"
                          :value="m"
                          text
                          :disabled="minuteDisabled(m)"
                      >
                        {{m}}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                        v-model="postChannels"
                        label="Канал"
                        placeholder="Канал"
                        outlined
                        multiple
                        :items="channels"
                        :item-text="'name'"
                        :item-value="'id'"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="info" @click="now()">Сейчас</v-btn>
                    <v-checkbox v-model="disableAlert" label="Без уведомления"></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    Время публикации: <strong>{{showTime}}</strong>
                    <p><v-btn color="primary" :disabled="hours===''" @click="post()">Запостить</v-btn></p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
				</v-card>

			</v-col>
			<v-col>
				<v-card>
					<editor :modelValue.sync="text" :links="links"></editor>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import httpClient from '../../services/http.service'
import _ from 'lodash'
import moment from 'moment'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'CreateSale',
  components:{
    editor: () => import('../../components/posts/Editor'),
    vueDropzone: vue2Dropzone,
  },
  data(){
    return{
      id: 0,
      sale: null,
      sales: [],
      saleIds: [], // список акций, с которыми взаимодействовали. нужен чтобы увеличить posts в таблице sales (кол-во публикаций акции)
      searchItems: [],
      searchIsLoading: false,
      searchString: '',
      text: '',
      links: [],
      hours: '',
      minutes: '',
      disableAlert: true,
      // channels: [],
      postChannels: [],
      dropzoneOptions: {
        url: 'https://backend.telestatic.ru/upload/sale?id=0',
        thumbnailWidth: 150,
        maxFilesize: 100,
        headers: { Authorization: 'Bearer '+ localStorage.getItem('ACCESS_TOKEN') }
      },
      imageUrl: null,
      imageKey: 0,
    }
  },
  created () {
    this.$store.dispatch('getChannels').then((data)=>{
      this.channels = data
    })
  },
  computed:{
    selectedTime(){
      return moment().hour(this.hours).minute(this.minutes).second(0).format('X')
    },
    showTime(){
      return moment().hour(this.hours).minute(this.minutes).format('HH:mm')
    },
    channels(){
      return this.$store.state.channels
    }
  },
  watch:{
    searchString(v){
      if (this.searchIsLoading) return
      if (!v) return
      this.searchIsLoading = true
      this.debSearchSale()
    },
    text(v){
      v = v.replace(/\n/g, '<br>')
      this.text = v
    }
  },
  methods:{
    post(){
      const info = {
        sale_ids: this.saleIds,
        text: this.text,
        channels: this.postChannels,
        publish_at: this.selectedTime,
        disable_notification: this.disableAlert,
      }
      httpClient.post('/posts/publish-sale', info).then(({data})=>{
        console.log('publish result: ', data)
      })
    },
    debSearchSale:_.debounce(function () {
      this.searchSale()
    }, 500),
    searchSale(){// поиск продукта в комбобоксе
      httpClient.get('/sales/search?search='+this.searchString).then(({data}) => {
        this.searchItems = data
        this.searchIsLoading = false
      })
    },
    selectSale(data){
      this.id = data.id
      this.imageUrl = data.image
      this.saleIds = _.union(this.saleIds, [data.id])
      this.sales.push(data)
      this.links.push({title: data.title, link: data.link})
      this.text += data.text + '<br><br>'
    },
    hourDisabled(h){
      h++
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(h, 0, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    minuteDisabled(m){
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(this.hours, m, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    now(){
      this.minutes = new Date().getMinutes()
      this.hours = new Date().getHours()
    },
    uploadSuccess(file, response){
      console.log('upload success', response)
      this.imageUrl = response
    },
    updateDZHeaders(){
      const url = 'https://backend.telestatic.ru/upload/sale?id=' + this.id
      console.log('updating headers... url: ', url)
      this.$refs.dropzone.setOption('url', url)
    },
  }
}
</script>

<style scoped>
	.v-btn-toggle {
		flex-direction: column;
	}
</style>
